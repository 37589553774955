/* eslint-disable */
import {
  DeleteOutlined,
  FormOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  Modal,
  Popconfirm,
  Row,
  Typography,
} from 'antd';
import Form from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  createNoteThunk,
  deleteNoteThunk,
  getPlayerDetailsThunk,
  updatePlayerDetailsThunk,
} from 'redux/reducers/players/playerDetails/playerDetailsThunk';
import classes from './PlayerInfoTab.module.scss';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const { TextArea } = Input;
const { Text } = Typography;

interface PlayerInfoProps {
  playerInfoData: any;
  loading: boolean;
}

const PlayerInfoTab: React.FC<PlayerInfoProps> = ({
  playerInfoData,
  loading,
}) => {
  const dispatch = useAppDispatch();

  const [note, setNote] = useState('');
  const [formData, setFormData] = useState(playerInfoData);
  const [isCreateNoteActive, setIsCreateNoteActive] = useState(false);

  const maxLength = 250;
  const playerId = formData?.id;

  const handleInputChange = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    const updatedData = {
      playerId: formData.id,
      data: {
        f_name: formData.f_name,
        l_name: formData.l_name,
        phone: formData.phone,
        e_mail: formData.e_mail,
        city: formData.city,
        address: formData.address,
        national_id_number: formData.national_id_number,
        verification_id: formData.verification_id,
        restore_account: false,
      },
    };
    dispatch(updatePlayerDetailsThunk(updatedData));
  };

  const handleSaveNote = (note: string) => {
    const playerId = formData?.id;
    dispatch(createNoteThunk({ playerId, note: note }));
    setIsCreateNoteActive(false);
    setNote('');
  };

  const handleCancel = () => {
    setIsCreateNoteActive(false);
  };

  const handleCreateNoteButton = () => {
    setIsCreateNoteActive(!isCreateNoteActive);
  };

  const handleDeleteNoteButton = (noteId: number) => {
    const playerId = formData?.id;
    dispatch(deleteNoteThunk({ playerId, noteId }));
  };

  useEffect(() => {
    setFormData(playerInfoData);
  }, [playerInfoData]);

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <div style={{ width: '100%' }}>
          {formData && (
            <Form
              layout='vertical'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}>
              <Row gutter={[24, 24]}>
                <Col span={6}>
                  <FormItem label='Username'>
                    <Input disabled size='large' value={formData?.username} />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label='Gender'>
                    <Input disabled size='large' value={formData?.gender} />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label='Referer'>
                    <Input disabled size='large' value={formData?.referer} />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label='Live Delay'>
                    <Input disabled size='large' value={formData?.live_delay} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col span={6}>
                  <FormItem label='First Name'>
                    <Input
                      size='large'
                      value={formData?.f_name}
                      onChange={e =>
                        handleInputChange('f_name', e.target.value)
                      }
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label='Country'>
                    <Input disabled size='large' value={formData?.country} />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label='Bet Limit'>
                    <Input disabled size='large' value={formData?.bet_limit} />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label='Auto Approve'>
                    <Input
                      disabled
                      size='large'
                      value={formData?.aa_enabled ? 'Enabled' : 'Disabled'}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[34, 24]}>
                <Col span={12}>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <FormItem label='Last Name'>
                        <Input
                          size='large'
                          value={formData?.l_name}
                          onChange={e =>
                            handleInputChange('l_name', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label='City'>
                        <Input
                          size='large'
                          value={formData?.city}
                          onChange={e =>
                            handleInputChange('city', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <FormItem label='Birth Date'>
                        <Input
                          disabled
                          size='large'
                          value={dayjs(formData.birthday).format('DD/MM/YYYY')}
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label='Address'>
                        <Input
                          size='large'
                          value={formData.address}
                          onChange={e =>
                            handleInputChange('address', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <FormItem label='Primary Phone'>
                        <Input
                          size='large'
                          value={formData.phone}
                          onChange={e =>
                            handleInputChange('phone', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label='ID Card'>
                        <Input
                          size='large'
                          value={formData.national_id_number}
                          onChange={e =>
                            handleInputChange(
                              'national_id_number',
                              e.target.value,
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <FormItem label='Email'>
                        <Input
                          size='large'
                          value={formData.e_mail}
                          onChange={e =>
                            handleInputChange('e_mail', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label='Verification ID'>
                        <Input
                          size='large'
                          value={formData.verification_id}
                          onChange={e =>
                            handleInputChange('verification_id', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <Row gutter={[24, 24]}>
                        <Col span={20} className={classes.notesHeader}>
                          <span>Notes</span>
                          <Button
                            type='primary'
                            shape='circle'
                            onClick={handleCreateNoteButton}>
                            <FormOutlined />
                          </Button>
                        </Col>
                      </Row>
                      <FormItem>
                        <Card size='small' className={classes.notesContent}>
                          {formData?.notes.length ? (
                            formData?.notes.map((item: any) => {
                              const dateObject = new Date(item.created_at);
                              return (
                                <Card size='small' key={item.id}>
                                  <p>
                                    <strong>Author:</strong>&nbsp;
                                    {item.author.username}
                                  </p>
                                  <div className={classes.notesCenter}>
                                    <div>
                                      <strong>Created At:</strong>&nbsp;
                                      {dateObject.toLocaleDateString()}
                                    </div>
                                    <div>
                                      <Popconfirm
                                        title='Delete the note'
                                        description='Are you sure to delete this note?'
                                        onConfirm={() =>
                                          handleDeleteNoteButton(item.id)
                                        }
                                        icon={
                                          <QuestionCircleOutlined
                                            style={{ color: 'red' }}
                                          />
                                        }>
                                        <Button danger type='link'>
                                          <DeleteOutlined
                                            style={{ fontSize: '26px' }}
                                          />
                                        </Button>
                                      </Popconfirm>
                                    </div>
                                  </div>
                                  <p
                                    style={{
                                      width: '90%',
                                      wordWrap: 'break-word',
                                    }}>
                                    <strong>Note:</strong>&nbsp;
                                    {item.note}
                                  </p>
                                </Card>
                              );
                            })
                          ) : (
                            <Empty
                              description={'No Notes'}
                              style={{ marginTop: '2rem', color: '8D8D8D' }}
                            />
                          )}
                        </Card>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label='Added Number'>
                        <Card
                          style={{ height: '15rem', overflow: 'auto' }}
                          size='small'>
                          {formData?.meta?.phone ? (
                            Object.entries(formData.meta.phone).map(
                              ([key, value]) => (
                                <p key={key}>
                                  <strong>{key}:</strong>
                                  <>
                                    {Array.isArray(value)
                                      ? value.join(', ')
                                      : value}
                                  </>
                                </p>
                              ),
                            )
                          ) : (
                            <Empty
                              description={'No Notes'}
                              style={{ marginTop: '2rem', color: '8D8D8D' }}
                            />
                          )}
                        </Card>
                      </FormItem>
                    </Col>
                  </Row>
                  <Modal
                    title='New Note'
                    open={isCreateNoteActive}
                    onOk={() => handleSaveNote(note)}
                    onCancel={handleCancel}>
                    <div>
                      <Text strong>Player:</Text> {playerId}
                    </div>
                    <TextArea
                      value={note}
                      onChange={e => setNote(e.target.value)}
                      maxLength={maxLength}
                      rows={4}
                      style={{
                        marginTop: '5px',
                        marginBottom: '5px',
                      }}
                    />
                    <div style={{ textAlign: 'right' }}>
                      {note.length}/{maxLength}
                    </div>
                  </Modal>
                  <Row style={{ marginTop: '5%' }} justify='space-between'>
                    <Col offset={16}>
                      <Button
                        style={{ width: 200 }}
                        type='primary'
                        onClick={handleSubmit}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </div>
      )}
    </>
  );
};

export default PlayerInfoTab;
