/* eslint-disable */

import dayjs from 'dayjs';
import { Tag } from 'antd';
import style from '../components/BalanceLogHeader/BalanceLogHeader.module.scss';
import {
  setActiveTabKey,
  setChoosenBetId,
} from 'redux/reducers/players/playersSlice';
import { useAppDispatch } from 'redux/hooks/redux.hooks';

export const useBalLogBigTableColumns = () => {
  const dispatch = useAppDispatch();

  const getCurrentBet = (id: any) => {
    console.log('mayis', id);

    dispatch(setActiveTabKey('5'));
    dispatch(setChoosenBetId(id.toString()));
  };

  return [
    {
      title: 'Log ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (updatedAt: string | null) =>
        updatedAt ? dayjs(updatedAt).utc().format('YYYY-MM-DD HH:mm:ss') : '',
    },
    {
      title: 'Old Balance',
      dataIndex: 'balance_old_value',
      key: 'balance_old_value',
    },
    {
      title: 'Action',
      dataIndex: 'balance_diff',
      key: 'balance_diff',
      render: (balance_diff: number) => (
        <Tag color={balance_diff < 0 ? 'red' : 'green'}>
          {balance_diff.toLocaleString()}
        </Tag>
      ),
    },
    {
      title: 'New Balance',
      dataIndex: 'balance_new_value',
      key: 'balance_new_value',
    },
    {
      title: 'OP Type',
      dataIndex: 'op',
      key: 'op',
      render: (op: string, record: any) => {
        const originId = record.origin_id;
        const isNumber = originId != null && Number.isFinite(Number(originId));
        if ((op === 'c-bet' || op === 'c-win') && isNumber) {
          return <a onClick={() => getCurrentBet(Number(originId))}>{op}</a>;
        }
        return op;
      },
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      render: (updatedAt: string | null) =>
        updatedAt ? dayjs(updatedAt).utc().format('YYYY-MM-DD HH:mm:ss') : '',
    },
  ];
};
export const balLogSmallTableColumns = [
  {
    title: 'Sport',
    className: style.customHeader,
    children: [
      { title: 'Bet', dataIndex: 'sport_total_bet', key: 'sport_total_bet' },
      { title: 'Win', dataIndex: 'sport_total_won', key: 'sport_total_won' },
      { title: 'GGR', dataIndex: 'sport_ggr', key: 'sport_ggr' },
    ],
  },
  {
    title: 'Casino',
    className: style.customHeader,
    children: [
      { title: 'Bet', dataIndex: 'casino_total_bet', key: 'casino_total_bet' },
      { title: 'Win', dataIndex: 'casino_total_won', key: 'casino_total_won' },
      { title: 'GGR', dataIndex: 'casino_ggr', key: 'casino_ggrgr' },
    ],
  },
  {
    title: 'Games',
    className: style.customHeader,
    children: [
      { title: 'Bet', dataIndex: 'games_total_bet', key: 'games_total_bet' },
      { title: 'Win', dataIndex: 'games_total_won', key: 'games_total_won' },
      { title: 'GGR', dataIndex: 'games_ggr', key: 'games_ggr' },
    ],
  },
];
