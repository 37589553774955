import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getPlayerTrxThunk = createAsyncThunk(
  'transaction/getPlayerTrx',
  async (data: any) => {
    console.log(data);

    const response = await axios.post('/transaction/list', {
      ...data,
      dateFrom: data.dateFrom.format(),
      dateTo: data.dateTo.format(),
    });
    return response.data;
  },
);

export const getTrxFiltersThunk = createAsyncThunk(
  'transaction/getTrxFilters',
  async () => {
    const response = await axios.post('/transaction/filters', {
      type: 'manual-insert',
    });
    return response.data;
  },
);

export const getPlayerForMove = createAsyncThunk(
  'transaction/getPlayerForMove',
  async (id: any) => {
    const obj = {
      user_id: id,
      limit: 25,
      page: 1,
    };
    const response = await axios.post('/player/list', obj);
    return response.data;
  },
);

export const moveTrx = createAsyncThunk(
  'transaction/moveTrx',
  async ({ trxId, userId }: { trxId: any; userId: any }) => {
    const response = await axios.post('/transaction/move', {
      transactionId: trxId,
      targetPlayerId: userId,
    });
    return response.data;
  },
);
