import { ColumnsType } from 'antd/es/table';

import { IInfoModaldata } from './CasinoSessionTypes';

export const infoModalcolumns: ColumnsType<IInfoModaldata> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Player Id',
    dataIndex: 'player_id',
    key: 'player_id',
    width: 100,
  },
  {
    title: 'Inserted At',
    dataIndex: 'inserted_at',
    key: 'inserted_at',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Freespin Id',
    dataIndex: 'freespin_id',
    key: 'freespin_id',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'Section',
    dataIndex: 'section',
    key: 'section',
  },
];
