import { ColumnsType } from 'antd/es/table';

import { SportBetStatus, sportBetStatusMap } from '../../../helpers/costants';

import { IInfoModaldata } from './SpTabGenTableModal.type';

export const infoModalcolumns: ColumnsType<IInfoModaldata> = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: SportBetStatus) => sportBetStatusMap[status],
  },
  {
    title: 'Event',
    dataIndex: 'event_id',
    key: 'event_id',
  },
  {
    title: 'Market',
    dataIndex: 'market_name',
    key: 'market_name',
  },
  {
    title: 'Price',
    dataIndex: 'price_name',
    key: 'price_name',
  },
  {
    title: 'Odd',
    dataIndex: 'odd',
    key: 'odd',
  },
  {
    title: 'Handicap',
    dataIndex: 'hcap',
    key: 'hcap',
  },
  {
    title: 'Bet',
    dataIndex: 'bet_amount',
    key: 'bet_amount',
  },
  {
    title: 'Win',
    dataIndex: 'win_amount',
    key: 'win_amount',
  },
];
