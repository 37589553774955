import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const shortTransactionData = createAsyncThunk(
  'transaction/fetchData',
  async (requestData: any, { rejectWithValue }) => {
    try {
      const response = await axios.post('/transaction/list', requestData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateTransactionStatus = async (
  transactionsIds: number[],
  status: string,
) => {
  try {
    const response = await axios.post('/transaction/update-status', {
      status,
      transactionsIds,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating status:', error);
  }
};
