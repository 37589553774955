/* eslint-disable */

import { FC, useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { getPlayerSportThunk } from 'redux/reducers/players/sport/playerSportThunk';

import {
  bettingOption,
  sportBetTypeOption,
  sportStatusOption,
} from '../../helpers/costants';
import { getSportCasinoGamesData } from 'redux/reducers/players/balanceLog/balanceLogThunk';
import { useSelector } from 'react-redux';

const { RangePicker } = DatePicker;

interface SportProps {
  currentPage: any;
  pageSize: any;
}

const SportTabFilter: FC<SportProps> = ({ currentPage, pageSize }) => {
  const [localData, setLocalData] = useState<any>({
    date_from: dayjs().subtract(1, 'week').startOf('day'),
    date_to: dayjs().endOf('day').set('hour', 23).set('minute', 59),
    money_options: bettingOption.map(option => option.value),
    ztype: sportBetTypeOption.map(option => option.key.toString()),
    status: sportStatusOption.map(option => option.key.toString()),
  });
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const activeTabKey = useSelector((state: any) => state.players.activeTabKey);

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      setLocalData((prevState: any) => ({
        ...prevState,
        date_from: start
          ? dayjs(start.format())
          : dayjs().subtract(1, 'week').startOf('day'),
        date_to: end
          ? dayjs(end.format())
          : dayjs().endOf('day').set('hour', 23).set('minute', 59),
      }));
    }
  };

  useEffect(() => {
    if (id && activeTabKey === '4') {
      const requestData = {
        limit: pageSize,
        page: currentPage,
        filters: { ...localData, player_id: parseInt(id) },
      };
      dispatch(getPlayerSportThunk(requestData));
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          date_from: localData.date_from,
          date_to: localData.date_to,
          sections: ['sport'],
        }),
      );
    }
  }, [currentPage, pageSize, activeTabKey]);

  const onSearch = () => {
    if (id) {
      const requestData = {
        limit: pageSize,
        page: currentPage,
        filters: { ...localData, player_id: parseInt(id) },
      };
      dispatch(getPlayerSportThunk(requestData));
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          date_from: localData.date_from,
          date_to: localData.date_to,
          sections: ['sport'],
        }),
      );
    }
  };

  const handleOptionChang = (name: string, values: string | string[]) => {
    const selectedValues = Array.isArray(values) ? values : [values];
    if (selectedValues.includes('All')) {
      let allKeys: any = [];
      if (name === 'ztype') {
        allKeys = sportBetTypeOption.map(option => option.key.toString());
      } else {
        allKeys = sportStatusOption.map(option => option.key.toString());
      }
      setLocalData((prevData: any) => ({
        ...prevData,
        [name]: allKeys,
      }));
    } else {
      let keys: any;
      if (name === 'ztype') {
        keys = selectedValues.map(value => {
          const option = sportBetTypeOption.find(opt => opt.value === value);
          return option ? option.key.toString() : value;
        });
      } else {
        keys = selectedValues.map(value => {
          const option = sportStatusOption.find(opt => opt.value === value);
          return option ? option.key.toString() : value;
        });
      }
      setLocalData((prevData: any) => ({
        ...prevData,
        [name]: keys,
      }));
    }
  };

  const handleOption = (key: string, value: string | string[]) => {
    if (key === 'money_options') {
      const selectedOptions = Array.isArray(value) ? value : [value];
      if (selectedOptions.includes('all')) {
        const allCasinoMoneyOptionValues = bettingOption.map(
          (agg: any) => agg.value,
        );
        setLocalData((prevState: any) => ({
          ...prevState,
          money_options: allCasinoMoneyOptionValues,
        }));
      } else {
        setLocalData((prevState: any) => ({
          ...prevState,
          money_options: selectedOptions as any,
        }));
      }
    }
  };

  const getValues = (name: string, keys: string[]) => {
    if (name === 'status') {
      return keys.map(key => {
        const option = sportStatusOption.find(
          opt => opt.key.toString() === key,
        );
        return option ? option.value : key;
      });
    } else {
      return keys.map(key => {
        const option = sportBetTypeOption.find(
          opt => opt.key.toString() === key,
        );
        return option ? option.value : key;
      });
    }
  };

  return (
    <Form layout='vertical' labelCol={{ span: 10 }}>
      <Row>
        <Col span={5}>
          <FormItem label='Select Range'>
            <RangePicker
              showTime
              defaultValue={[localData.date_from, localData.date_to]}
              onChange={handleRangeChange}
            />
          </FormItem>
        </Col>
        <Col span={3} offset={1}>
          <FormItem label='Bet Type'>
            <Select
              maxTagCount={1}
              mode='multiple'
              style={{ width: '100%' }}
              value={getValues('ztype', localData.ztype)}
              onChange={(values: string | string[]) =>
                handleOptionChang('ztype', values)
              }
              options={[
                { label: 'All', value: 'All' },
                ...sportBetTypeOption.map(option => ({
                  label: option.value,
                  value: option.value,
                })),
              ]}
              placeholder='Select Bet Type options'
            />
          </FormItem>
        </Col>
        <Col span={3} offset={1}>
          <FormItem label='Status'>
            <Select
              maxTagCount={1}
              mode='multiple'
              style={{ width: '100%' }}
              value={getValues('status', localData.status)}
              onChange={(values: string | string[]) =>
                handleOptionChang('status', values)
              }
              options={[
                { label: 'All', value: 'All' },
                ...sportStatusOption.map(option => ({
                  label: option.value,
                  value: option.value,
                })),
              ]}
              placeholder='Select Status options'
            />
          </FormItem>
        </Col>
        <Col span={5} offset={1}>
          <FormItem label='Betting Option'>
            <Select
              maxTagCount={1}
              mode='multiple'
              style={{ width: '100%' }}
              value={localData.money_options}
              onChange={(value: string | string[]) =>
                handleOption('money_options', value)
              }
              options={[{ label: 'All', value: 'all' }, ...bettingOption]}
            />
          </FormItem>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          offset={1}
          span={4}>
          <FormItem label='&nbsp;'>
            <Button onClick={onSearch} type='primary'>
              Search
            </Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default SportTabFilter;
