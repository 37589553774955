/* eslint-disable */

import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Table,
} from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { getPlayersThunk } from 'redux/reducers/players/playersThunk';
import { PlayersState } from 'redux/reducers/players/types';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import {
  clearData,
  setActiveTabKey,
} from 'redux/reducers/players/playersSlice';

import { inputFields } from './helpers/constans';
import { playerTableColumns } from './Players.configs';

const Players: FC = () => {
  const [form] = Form.useForm();
  const playerStatusOptions = [
    { value: 'all', label: 'All' },
    { value: 'deleted', label: 'Deleted' },
  ];
  const [playerStatusValue, setPlayerStatusValue] = useState('all');
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const dispatch = useAppDispatch();
  const { data, loading, count } = useSelector(
    (state: { players: PlayersState }) => state.players,
  );
  const { activeProjectId } = useSelector((state: any) => state.projects);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPage(1);
    if (playerStatusValue === 'all') {
      dispatch(getPlayersThunk({ ...formData, page: 1, limit: pageSize }));
    } else {
      dispatch(
        getPlayersThunk({
          type: 'deleted',
          page: 1,
          limit: pageSize,
        }),
      );
    }
  }, [activeProjectId]);

  useEffect(() => {
    dispatch(setActiveTabKey('1'));
    if (Array.isArray(data) && data.length === 1) {
      const playerId = data[0].id;
      dispatch(clearData());
      navigate(`/players/${playerId}`);
    }
  }, [data, navigate]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const onPlayerStatusChange = (value: string) => {
    setPlayerStatusValue(value);
  };

  const onSearch = () => {
    setCurrentPage(1);
    if (playerStatusValue === 'all') {
      dispatch(getPlayersThunk({ ...formData, page: 1, limit: pageSize }));
    } else {
      dispatch(
        getPlayersThunk({
          type: 'deleted',
          page: 1,
          limit: pageSize,
        }),
      );
    }
  };

  const onBirthdayChange =
    (fieldName: string) => (date: any, dateString: string) => {
      setFormData(prevFormData => ({
        ...prevFormData,
        [fieldName]: dateString,
      }));
    };

  const handleTableChange = (pagination: any) => {
    const { current, pageSize } = pagination;
    setCurrentPage(current);
    setPageSize(pageSize);
    if (playerStatusValue === 'all') {
      dispatch(
        getPlayersThunk({ ...formData, page: current, limit: pageSize }),
      );
    } else {
      dispatch(
        getPlayersThunk({ type: 'deleted', page: current, limit: pageSize }),
      );
    }
  };

  const handleResetFilters = () => {
    form.resetFields();
    setFormData({});
    setPlayerStatusValue('all');
    setCurrentPage(1);
    setPageSize(50);
    dispatch(getPlayersThunk({ page: 1, limit: 50 }));
  };

  return (
    <>
      <Divider orientation='left'>Players</Divider>
      <Form
        form={form}
        initialValues={{
          playerStatus: playerStatusOptions[0].value,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Form.Item name='playerStatus' label='Player status'>
            <Select
              onChange={onPlayerStatusChange}
              value={playerStatusValue}
              style={{
                width: '20rem',
              }}
              options={playerStatusOptions}
            />
          </Form.Item>
          <Button
            onClick={onSearch}
            style={{ marginBottom: 24 }}
            type='primary'>
            <SearchOutlined />
          </Button>
          <Button
            onClick={handleResetFilters}
            style={{ marginLeft: 16, marginBottom: 24 }}
            type='default'>
            <ReloadOutlined /> Reset Filters
          </Button>
        </div>
        <Row style={{ marginBottom: 20 }} gutter={[40, 32]}>
          {playerStatusValue === 'all' && (
            <>
              {inputFields.map(field => (
                <Col key={field.name} span={4}>
                  <Form.Item
                    name={field.name}
                    rules={
                      ['balanceFrom', 'balanceTo'].includes(field.name)
                        ? [
                            {
                              required: true,
                              message: `Please input ${field.placeholder}`,
                            },
                            {
                              pattern: /^\d+$/,
                              message: `${field.placeholder} must be a number`,
                            },
                          ]
                        : undefined
                    }>
                    <Input
                      type='text'
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name] || ''}
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                </Col>
              ))}
              <Col span={4}>
                <Form.Item name='birthdayFrom'>
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder='Birthday from'
                    value={
                      formData.birthdayFrom
                        ? dayjs(formData.birthdayFrom)
                        : null
                    }
                    allowClear={false}
                    onChange={(date, dateString) =>
                      onBirthdayChange('birthdayFrom')(date, dateString)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name='birthdayTo'>
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder='Birthday to'
                    value={
                      formData.birthdayTo ? dayjs(formData.birthdayTo) : null
                    }
                    allowClear={false}
                    onChange={(date, dateString) =>
                      onBirthdayChange('birthdayTo')(date, dateString)
                    }
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
      {loading ? (
        <GlobalLoader />
      ) : (
        Array.isArray(data) &&
        data.length > 1 && (
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: 20 }}>
              Total: {count}
            </div>
            <Table
              size='small'
              dataSource={data}
              columns={playerTableColumns}
              rowKey={record => record.id}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: count,
                pageSizeOptions: ['10', '20', '50', '100'],
                showQuickJumper: true,
                showSizeChanger: true,
              }}
              onChange={handleTableChange}
            />
          </div>
        )
      )}
    </>
  );
};

export default Players;
