/* eslint-disable */

import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import BalanceLogHeader from './components/BalanceLogHeader/BalanceLogHeader';
import classes from './BalanceLogTab.module.scss';
import { useBalLogBigTableColumns } from './helpers/costants';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { fixBalanaceLogThunk } from 'redux/reducers/players/balanceLog/balanceLogThunk';
import { useParams } from 'react-router-dom';
import { setError } from 'redux/reducers/players/balanceLog/balanceLogSlice';

const CustomTable: FC = () => {
  const { list, count } = useSelector(
    (state: any) => state.balanceLogSlice.data,
  );
  const { id } = useParams<{ id: any }>();
  const { loading } = useSelector((state: any) => state.balanceLogSlice);
  const { error } = useSelector((state: any) => state.balanceLogSlice.fix);
  const [selectedRowKey, setSelectedRowKey] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [fixedLog, setFixedLog] = useState();
  const [pageSize, setPageSize] = useState(50);
  const balLogBigTableColumns = useBalLogBigTableColumns();

  const handleTableChange = (pagination: any) => {
    const { current, pageSize } = pagination;
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (error) {
      setErrorModalVisible(true);
    } else {
      setErrorModalVisible(false);
    }
  }, [error]);

  const columnsWithAction = [
    ...balLogBigTableColumns,
    {
      title: 'Fix',
      dataIndex: 'fix',
      key: 'fixd',
      render: (_: any, record: any) =>
        record.fixed === 1
          ? 'Fixed'
          : record.undo_status && (
              <Button
                onClick={() => handleFixClick(record.id)}
                key={record.id}
                style={{ width: '60%' }}
                type='primary'>
                FIX
              </Button>
            ),
    },
  ];

  const handleFixClick = (log_id: any) => {
    dispatch(fixBalanaceLogThunk({ force: false, player_id: id, log_id }));
    setFixedLog(log_id);
  };

  const handleConfirmError = () => {
    dispatch(
      fixBalanaceLogThunk({ force: true, player_id: id, log_id: fixedLog }),
    );
    setErrorModalVisible(false);
    dispatch(setError({}));
  };

  const onCancel = () => {
    setErrorModalVisible(false);
    dispatch(setError({}));
  };

  return (
    <>
      {error.error_code === 1 && (
        <Modal
          title='Error'
          visible={errorModalVisible}
          onCancel={onCancel}
          footer={[
            <Button key='cancel' onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              key='confirm'
              type='primary'
              onClick={() => handleConfirmError()}>
              Confirm
            </Button>,
          ]}>
          <p>{error.message}</p>
        </Modal>
      )}
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <BalanceLogHeader
            setSelectedRowKey={setSelectedRowKey}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <Table
            rowClassName={(record, index) =>
              index === selectedRowKey ? `scroll-row ${classes.scrollRow}` : ''
            }
            bordered
            size='small'
            columns={columnsWithAction}
            scroll={{ y: 700 }}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              pageSizeOptions: ['10', '20', '50', '100'],
              showQuickJumper: true,
              showSizeChanger: true,
              total: count,
            }}
            loading={loading}
            dataSource={list}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomTable;
