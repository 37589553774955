import { createSlice } from '@reduxjs/toolkit';

import { getPlayersThunk } from './playersThunk';
import { PlayersState } from './types';

const initialState: PlayersState = {
  data: [],
  count: 0,
  activeTabKey: '1',
  choosenBetId: null,
  loading: false,
  error: null,
};

const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    clearData(state) {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
    setActiveTabKey(state, action) {
      state.activeTabKey = action.payload;
    },
    setChoosenBetId(state, action) {
      state.choosenBetId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPlayersThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlayersThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.list;
        state.count = action.payload.count;
      })
      .addCase(getPlayersThunk.rejected, state => {
        state.loading = false;
        state.error = 'Failed to fetch players';
      });
  },
});

export const { clearData, setActiveTabKey, setChoosenBetId } =
  playersSlice.actions;

export default playersSlice.reducer;
