/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Table,
  TreeSelect,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  getPlayerTrxThunk,
  getTrxFiltersThunk,
} from 'redux/reducers/players/playerTrx/playerTrxThunk';
import { useSelector } from 'react-redux';
import {
  columnsInOut,
  trxApprovedTypeTreeOptions,
  trxStatusTypeOption,
} from '../../helpers/costants';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { transformTrxFilters } from 'components/Routes/Players/Player/helpers/constans';

interface TRXTabFilterProps {
  id: string | undefined;
  currentPage: any;
  pageSize: any;
  setCurrentPage: (page: any) => void;
}

interface TrxTypeOption {
  title: string;
  value: string;
  children: {
    title: string;
    value: string;
  }[];
}

const TRXTabFilter: React.FC<TRXTabFilterProps> = ({
  id,
  currentPage,
  setCurrentPage,
  pageSize,
}) => {
  const [trxTypeOptions, setTrxTypeOptions] = useState<TrxTypeOption[]>([]);
  const [localData, setLocalData] = useState({
    status: [],
    aa_status: trxApprovedTypeTreeOptions.map(option => option.key),
    opType: [],
    dateFrom: dayjs().subtract(1, 'week').startOf('day'),
    dateTo: dayjs().endOf('day').set('hour', 23).set('minute', 59),
  });
  const dispatch = useAppDispatch();
  const { trxFilters } = useSelector((state: any) => state.playerTrxSlice);
  const {
    deposit_amount,
    total_deposit_amount,
    total_withdraw_amount,
    withdraw_amount,
  } = useSelector((state: any) => state.playerTrxSlice.playerTrx.data);

  useEffect(() => {
    dispatch(getTrxFiltersThunk());
  }, [dispatch]);

  useEffect(() => {
    if (trxFilters) {
      const transformedOptions = transformTrxFilters(trxFilters);
      setTrxTypeOptions(transformedOptions);
      const allChildOptions = transformedOptions.reduce<string[]>(
        (acc, curr) => {
          curr.children.forEach(child => acc.push(child.value));
          return acc;
        },
        [],
      );
      setLocalData((prevState: any) => ({
        ...prevState,
        opType: allChildOptions,
      }));
    }
  }, [trxFilters]);

  useEffect(() => {
    const allStatusValues = trxStatusTypeOption.map(option => option.value);
    setLocalData((prevState: any) => ({
      ...prevState,
      status: allStatusValues,
    }));
  }, []);

  useEffect(() => {
    const hasFilters = Object.keys(localData).length > 0;
    if (id && hasFilters) {
      const obj = {
        ...localData,
        playerId: parseInt(id),
        limit: pageSize,
        page: currentPage,
        orderBy: 'created_at',
        orderDir: 'DESC',
      };
      dispatch(getPlayerTrxThunk(obj));
    }
  }, [id, currentPage, pageSize]);

  const handleInputChangeOpType = (name: string, value: string | string[]) => {
    setLocalData(prevState => ({
      ...prevState,
      [name]: Array.isArray(value) ? value : [value],
    }));
  };

  const handleDateChange = (date: Dayjs | null, key: string) => {
    if (date) {
      setLocalData(prevState => ({
        ...prevState,
        [key]: date,
      }));
    }
  };

  const handleSearch = () => {
    const hasFilters = Object.keys(localData).length > 0;
    if (id && hasFilters) {
      const obj = {
        ...localData,
        playerId: parseInt(id),
        limit: pageSize,
        page: currentPage,
        orderBy: 'created_at',
        orderDir: 'DESC',
      };
      dispatch(getPlayerTrxThunk(obj));
      setCurrentPage(1);
    }
  };

  const dataInOut = [
    {
      key: '1',
      label1: 'In',
      data1: deposit_amount,
      label2: 'Total In',
      data2: total_deposit_amount,
    },
    {
      key: '2',
      label1: 'Out',
      data1: withdraw_amount,
      label2: 'Total Out',
      data2: total_withdraw_amount,
    },
  ];

  const handleOption = (key: string, value: string | string[]) => {
    const selectedOptions = Array.isArray(value) ? value : [value];
    if (selectedOptions.includes('all')) {
      let allValues: any;
      if (key === 'aa_status') {
        allValues = trxApprovedTypeTreeOptions.map((agg: any) => agg.value);
      } else {
        allValues = trxStatusTypeOption.map((agg: any) => agg.value);
      }
      setLocalData(prevState => ({
        ...prevState,
        [key]: allValues,
      }));
    } else {
      setLocalData(prevState => ({
        ...prevState,
        [key]: selectedOptions as any,
      }));
    }
  };

  return (
    <>
      {trxFilters.loading ? (
        <GlobalLoader />
      ) : (
        <Form layout='vertical' labelCol={{ span: 10 }}>
          <Row gutter={16}>
            <Col span={16}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item style={{ fontSize: '17px' }} label='TRX Type'>
                    <TreeSelect
                      maxTagCount={1}
                      treeCheckable
                      showSearch={false}
                      treeData={trxTypeOptions}
                      style={{ width: '100%' }}
                      placeholder='Select TRX Type'
                      onChange={value =>
                        handleInputChangeOpType('opType', value)
                      }
                      value={localData.opType}
                    />
                  </Form.Item>
                </Col>
                <Col span={6.5}>
                  <Form.Item label='Start Date'>
                    <DatePicker
                      showTime
                      value={localData.dateFrom}
                      onChange={date => handleDateChange(date, 'dateFrom')}
                    />
                  </Form.Item>
                </Col>
                <Col span={6.5}>
                  <Form.Item label='End Date'>
                    <DatePicker
                      showTime
                      value={localData.dateTo}
                      onChange={date => handleDateChange(date, 'dateTo')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item style={{ fontSize: '17px' }} label='Approved Type'>
                    <Select
                      maxTagCount={1}
                      mode='multiple'
                      style={{ width: '100%' }}
                      value={localData.aa_status}
                      onChange={(value: string | string[]) =>
                        handleOption('aa_status', value)
                      }
                      options={[
                        { label: 'All', value: 'all' },
                        ...trxApprovedTypeTreeOptions,
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item style={{ fontSize: '17px' }} label='TRX Status'>
                    <Select
                      style={{ width: '100%' }}
                      maxTagCount={1}
                      mode='multiple'
                      value={localData.status}
                      onChange={(value: string | string[]) =>
                        handleOption('status', value)
                      }
                      options={[
                        { label: 'All', value: 'all' },
                        ...trxStatusTypeOption,
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='&nbsp;'>
                    <Button
                      style={{ width: '50%' }}
                      type='primary'
                      onClick={handleSearch}>
                      Search
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Table
                dataSource={dataInOut}
                columns={columnsInOut}
                pagination={false}
                bordered
                showHeader={false}
              />
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{ textAlign: 'right', paddingTop: '10px' }}></Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default TRXTabFilter;
